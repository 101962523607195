<template>
  <div>
    <div>
      <div class="m-2">
        <!-- <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            />
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  v-b-modal="'modal-add'"
                  variant="primary"
                >
                  <span class="text-nowrap">Add Language</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div> -->
        <b-table
          ref="table"
          :items="langauges"
          :fields="languageTable"
          aria-busy="true"
          class="position-relative"
          responsive
          primary-key="id"
          striped
          hover
        >
          <template #cell(id)="data">
            # {{ data.item.id }}
          </template>

          <template #cell(name)="data">
            {{ data.item.name }}
          </template>

          <template #cell(code)="data">
            <span v-if="data.item.code">
              {{ data.item.code }}
            </span>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      v-if="show"
      id="modal-add"
      centered
      title="Add Contact"
      hide-footer
    >
      <b-form
        ref="myForm"
        class="p-2"
        @submit.enter.prevent=""
      >
        <b-form-group
          label="Name"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="languageData.name"
            placeholder="Name"
          />
        </b-form-group>
        <b-form-group
          label="Code"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="languageData.code"
            placeholder="Code"
          />
        </b-form-group>
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="submit"
            @click="addLanguage()"
          >
            Add
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, onUnmounted,
} from '@vue/composition-api'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink, BImg, BFormFile, BCardText, BMediaBody, BMediaAside,
  BDropdown, BDropdownItem, BPagination, BFormGroup, BFormInvalidFeedback, BFormTextarea, BFormSelect, VBModal, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import handleFormData from '@/common/compositions/handleFormData'

Vue.use(VueSweetalert2)

export default {
  components: {
    BCard,
    BMediaAside,
    BCardText,
    BMediaBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BForm,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      editData: {},
      show: true,
      langauges: [],
      languageTable: [
        { key: 'id', sortable: true },
        { key: 'name', sortable: true },
        { key: 'code', sortable: true },
      ],
      languageData: {
        name: '',
        code: '',
      },
    }
  },
  setup() {
    const { formData, setFormData } = handleFormData()

    return {
      formData, setFormData,
    }
  },
  created() {
    this.getLanguages()
  },
  methods: {
    async getLanguages() {
      await axios.get('get/language').then(res => {
        this.langauges = res.data.success
      })
    },
    addLanguage() {
      axios.post('add/language', this.languageData).then(res => {
        if (res.status === 200) {
          this.getLanguages()
          this.$bvModal.hide('modal-add')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Added Successfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
